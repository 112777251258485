import React, { useState } from 'react';

import ampliphy from './img/ampliphy.svg';
import './App.css';
import './dealfinder-login.webflow.css';
import _ from 'lodash'
import {
    SalesforceConnector
} from './sf-connector'

import StartupsTable from './components/StartupTable'
import {STARTUP_FIELDS, HISTORY_FIELDS}  from './consts'

const salesforceConnector = new SalesforceConnector()

function getSorterFieldByDecionsHeader(header){
    switch (header){
        case 'Decision':
            return 'type';
        case 'Company':
            return  'Company Name';
        default:
            return 'date'
    }
}

function getDirectionArrow(isDescOrder){
    return isDescOrder === false ? '▲' : '▼'
}

function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [startups, setStartups] = useState([]);
    const [decisions, setDecicions] = useState([]);
    const [currentView, setCurrentView] = useState('startups');
    const [isLoggedIn, setIsLoggedIn] = useState(salesforceConnector.isLoggedIn());
    const [historySorterField, setHistorySorterField] = useState('date');
    const [isDescOrder, setHistoryOrder] = useState(false);
    const [historyFields, setHistoryHeaders] = useState(HISTORY_FIELDS)
    const [showFullDescription, SetFullDescription] = useState(false);

    function toggleDescription(){
        return SetFullDescription(!showFullDescription)
    }
    async function initApp(){
        const decisions= await salesforceConnector.getDealFinderHistory();
        setDecicions(decisions);
        const startups = await salesforceConnector.getDealFinderStartups();
        setStartups(_.differenceBy(startups,decisions,'startupId'));
        setIsLoading(false);
    }

    function login(){
        setIsLoading(true);
        salesforceConnector.login();
        setIsLoggedIn(true)
    }

    function sortRecords(header){
        if(currentView === 'history'){
            const newSorter = getSorterFieldByDecionsHeader(header)
            if(newSorter !== historySorterField){
                setHistorySorterField(newSorter)
            }
            setHistoryOrder(!isDescOrder);
            setHistoryHeaders(HISTORY_FIELDS.map(({name})=> ({name, direction: name === header ? getDirectionArrow(isDescOrder) : ''})))
            setDecicions(_.orderBy(decisions,[newSorter],[isDescOrder ? 'desc' : 'asc']))
        }
    }

    salesforceConnector.onceReady(initApp)

    return (
        <div className="App">
        {
                !isLoggedIn && (
                <div className="welcome">
                    <div className="div-block"><img src={ampliphy} alt="" className="image"/>
                        <div className="text-block">
                               <strong>
                                <em className="italic-text">
                                    Welcome to Dealfinder
                                </em>
                            </strong>
                        </div>
                        <button className="button w-button" onClick={login}>  Sign In </button>
                    </div>
                </div>
            )
        }

        {
            isLoggedIn && (
                <StartupsTable salesforceConnector={salesforceConnector} isLoggedIn={isLoggedIn} isLoading={isLoading}
                    setIsLoading={setIsLoading} startups={startups} decisions={decisions} currentView={currentView}
                   setCurrentView={setCurrentView} setDecicions={setDecicions} setStartups={setStartups} sortRecords={sortRecords}
                   historyFields={historyFields} startupFields={STARTUP_FIELDS}
                   toggleDescription={toggleDescription} showFullDescription={showFullDescription}
                />
            )
        }
        </div>
    )
}

export default App;