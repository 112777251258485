export const DEAL_FINDER_APP_CLIENT_ID = '3MVG98_Psg5cppyawYB96o0JMRqgKUckuf47mT9FQXNZ7z8jPVOgk2J9Zlnr7fC8R27.YHzYHb8XVjUiVt8HD';
export const DEAL_FINDER_APP_REDIRECT_URL = 'https://dealfinder.aleph.vc/';
export const TESTING_APP_CLIENT_ID = '3MVG98_Psg5cppyawYB96o0JMRj1Cczhlo_rmIuu4.dWl7b8RaIjj8LzM.tNFET0MSFoYWevnxBAvHVFibduu';
export const TESTING_APP_REDIRECT_URL = 'https://localhost:3000/';

function isProd(){
    return window.location.hostname ==='dealfinder.aleph.vc'
}
export function getSfConfig(){
    const prodConfig = {
        clientId: DEAL_FINDER_APP_CLIENT_ID,
        redirectUri: DEAL_FINDER_APP_REDIRECT_URL
    }
    const devConfig = {
        clientId: TESTING_APP_CLIENT_ID,
        redirectUri: TESTING_APP_REDIRECT_URL
    }

    return isProd() ? prodConfig : devConfig
}