import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import logo from '../img/aleph.svg';
import moment from 'moment'
import linkedin from '../img/linkedin.svg';
import crunchbase from '../img/crunchbase.svg';
import salesforce from '../img/salesforce.svg';
import reachout from '../img/action-buttons/reach.svg';
import snooze1q from '../img/action-buttons/1Q.svg';
import snooze2q from '../img/action-buttons/2Q.svg';
import pass from '../img/action-buttons/pass.svg';
import historyReachout from '../img/history-actions/reach.svg';
import historySnooze1q from '../img/history-actions/1q.svg';
import historySnooze2q from '../img/history-actions/2q.svg';
import historyPass from '../img/history-actions/pass.svg';
import Popup from "reactjs-popup";

import _ from 'lodash'

function Employees(props){
    return (
        <td className="startup-cell" key={props.value}>
            {!!props.url ?<a href={props.url} target="_blank" rel="noopener noreferrer"> <img src={linkedin} alt={'Linkedin profile'} /> </a> : ''}
            <span className="employees"> {props.value || 'N/A'} </span>
        </td>
    );
}

function Funding(props){
    return (
        <td className="startup-cell" key={props.value}>
            <div className='funding-amount'>
                {!!props.url ? <a href={props.url} target="_blank" rel="noopener noreferrer"> <img src={crunchbase} alt={'Crunchbase profile'} /> </a> : ''}

            <span className="funding"> {props.value.toLocaleString('en')
            || 'N/A'} </span></div>
            <span className='funding-date'>
                {moment(props.date).format('D MMM YYYY')}
            </span>
        </td>
    );
}

function getDirectionIcon(direction){
    switch (direction) {
        case 'Positive':
            return '▲'
        case 'Negative':
            return '▼'
        case 'Neutral':

            return '■'
        default:
            return ''
    }
}

function getDecisionIcon(type){
    switch (type) {
        case 'reachout':
            return historyReachout;
        case 'snooze1q':
            return historySnooze1q;
        case 'snooze2q':
            return historySnooze2q;
        case 'pass':
            return historyPass;
        default:
            return ''
    }
}

function SignalsSummaryLine(props){
    return(
        <div className="signals">
            <span className={(props.details.direction.toLowerCase())+' signal'}>
                {getDirectionIcon(props.details.direction)} {props.details.count}
            </span>
            <span> {props.details.type}</span>
        </div>
    )

}
function Signals(props){
    function renderSummaryLine(details,index) {
        return <SignalsSummaryLine details={details} key={index} />;
    }

    function formatSignals(signals){
        const groupedByType = _(signals)
            .groupBy(({type}) => type)
            .value();
        const formatted = [];

        _.forIn(groupedByType,(value, key) => {
            const counts = _.countBy(value, ({direction}) => direction)
            _.forIn(counts, (count, direction) => formatted.push({count,direction,type: key}));
        });

        return formatted
    }

    const formattedSignals = formatSignals(props.signals);
    return (
        <td className="startup-cell" key='signals'>
            <a href={props.url} target="_blank" rel="noopener noreferrer"> <img src={salesforce} alt={'Salesforce Page'} /> </a>
            {formattedSignals.map(renderSummaryLine)}
        </td>
    );
}

function Description(props){
    if(!props.value){
        return (
            <td className="startup-cell description" key="description">
                N/A
            </td>
        )
    }
    const previewText = _.truncate(props.value, {
        'length': Math.max(90, props.signalLines*30),
        'separator': ' ',
        'omission':''
    });

    const style = {
        padding: '50px 50px 50px 50px',
        border: '1px solid #979797',
        width: '620px',
        background: '#FFFFFF',
        'box-sizing': 'border-box',
        'box-shadow': '4px 4px 0px rgba(0, 0, 0, 0.3)',
        'border-radius': '5px'
    }
    const descriptionPopup =
        <Popup trigger={<button className="popup-button"> More »</button>} position="bottom center"
               contentStyle={style}
        >
            {close => (
                <div>
                    {props.value}
                </div>
            )}
        </Popup>
    let descText;
    if (props.showFullDescription) {
        descText= <div> {props.value}</div>
    } else {
        descText = <div>{previewText} {descriptionPopup}</div>

    }

    return (
        <td className={props.showFullDescription ?"startup-cell description-wide" : "startup-cell"}  key='description'>
            {descText}
        </td>
    )
}

function ActionButtons(props) {
    return (
        <td className="startup-cell" key='Decision'>
            <button className="decision" onClick={_.partial(props.handleAction,'reachout')}> <img src={reachout} alt={'reachout'} /> </button>
            <button className="decision" onClick={_.partial(props.handleAction,'snooze1q')}> <img src={snooze1q} alt={'snooze 1 Q'} /> </button>
            <button className="decision" onClick={_.partial(props.handleAction,'snooze2q')}> <img src={snooze2q} alt={'snooze 2 Qs'} /> </button>
            <button className="decision" onClick={_.partial(props.handleAction,'pass')}> <img src={pass} alt={'pass'}/> </button>
        </td>
    );
}

function DecisionForm(props){
    const decision = props.decision;
    return (
        <form>
            <textarea rows="3" value={decision} onChange={ (event) => props.onDecisionChange(event.target.value)} className="notes-input"/>
        </form>
    );
}

function StartupRow(props) {
    function getRowClass(){
       return props.odd ? "startup-row odd-background" : "startup-row"
   }

    const startup = props.startup;
    return (
        <tr className={getRowClass()}>
            <td className="startup-cell" key='companyName'>
                { !!startup['Website'] ? <a href={startup['Website']} target="_blank" rel="noopener noreferrer"> {startup['Company Name']+ ' »'}</a>:startup['Company Name']}
            </td>
            <td className="startup-cell" key='industry'>
                {startup['Industry'] || 'N/A'}
            </td>

            <Description showFullDescription={props.showFullDescription} value={startup['Description']} signalLines={startup['signals'].length} />

            <td className="startup-cell headcount" key='headcount'>
                {startup['Headcount'] || 'N/A'}
            </td>

            <Employees value={startup['Employees']} url = {startup['linkedinUrl']} />

            <td className="startup-cell" key='founded'>
                {startup['Founded'] || 'N/A'}
            </td>

            <Funding value={startup['Funding'] || 'N/A'} date={startup['Last Received Funding Date']} url = {startup['crunchbaseUrl']}/>

            <Signals signals={startup['signals'] || 'N/A'} url = {startup['salesforceUrl']}/>

            <td className="startup-cell notes" key='Notes'>
                <DecisionForm onDecisionChange={_.partial(props.onDecisionChange,startup)} />
            </td>
            <ActionButtons startup={startup} handleAction={_.partial(props.handleAction, props.startup)}/>
        </tr>
    );
}

function DecisionRow(props) {
    function getRowClass(){
        return props.odd ? "decision-row odd-background" : "decision-row"
    }
    const decision = props.decision;
    return (
        <tr className={getRowClass()}>
            <td className="startup-cell companyName" key='companyName'>
                { !!decision['Website'] ? <a href={decision['Website']} target="_blank" rel="noopener noreferrer"> {decision['Company Name']+ ' »'}</a>:decision['Company Name']}
            </td>
            <td className="startup-cell" key='Notes'>
                <a href={decision['salesforceUrl']} target="_blank" rel="noopener noreferrer"> <img src={salesforce} alt={'Salesforce Page'} /> </a>
                <img src={getDecisionIcon(decision['type'])} className="decision-img" alt={decision['type']}/> {decision['Notes']}
            </td>
            <td className="startup-cell date" key='date'>
                {moment(decision['date']).format('D MMM YYYY')}
            </td>
        </tr>
    );
}

function HeaderRow(props) {
    const fields = props.currentView === 'startups' ? props.startupFields : props.historyFields;
    return (
        <thead>
        <tr>
            {fields.map(({name, direction}) =>
                <th className={`header-cell col-${name.toLowerCase()}`} key={name} onClick={_.partial(props.sortRecords,name)}>
                    <span className='header-direction'>
                        {direction}
                    </span>
                    {name}
                    {
                        name === 'Description' &&
                        <label>
                            &nbsp; &nbsp;<input type="checkbox" checked={props.showFullDescription}
                                   onChange={props.toggleDescription}
                            />
                            &nbsp; full
                        </label>
                    }
                </th>
            )}
        </tr>
        </thead>
    );
}

export default function StartupsTable(props) {
    function handleHistoryClick() {
        props.setCurrentView('history');
    }

    function handleDealFinderClick() {
        props.setCurrentView('startups');
    }

    function onDecisionChange(startup,details){
        props.setStartups(props.startups.map(s => s.startupId ===startup.startupId ? _.set(s, 'Notes',details) : s));
    }

    function handleAction(startup,type) {
        const updatedDecisions = _(props.decisions)
            .concat({
            ..._.pick(startup,['Website','Company Name','startupId','Notes']),
            type,
            date: moment()
        })
            .sortBy('date')
            .reverse()
            .value();
        props.setDecicions(updatedDecisions);
        props.setStartups(props.startups.filter(({startupId: sId}) => sId !== startup.startupId))
        return props.salesforceConnector.getHandlerByType(type)(startup)
    }

    function renderStartup(startup,index) {
        return <StartupRow onDecisionChange={onDecisionChange} startup={startup} key={startup.startupId} odd={!!(index % 2)} handleAction={handleAction} showFullDescription={props.showFullDescription}/>;
    }

    function renderDecision(decision,index){
        return <DecisionRow decision={decision} key={decision.startupId} odd={!!(index % 2)} />;
    }

    const defaultTitleClass= "header-titles";
    const dealFinderTitleClass = `${defaultTitleClass}${props.currentView === 'startups' ? ' selected' : ''}`;
    const historyTitleClass = `${defaultTitleClass}${props.currentView === 'history' ? ' selected' : ''}`;

    return (
        <div>
            <div>
                <div className="header">
                    <img src={logo} className="header-logo" alt={''}/>
                    <span className={dealFinderTitleClass} onClick={handleDealFinderClick} > DealFinder </span>
                    <span className={historyTitleClass} onClick={handleHistoryClick}> History </span>
                </div>
            </div>
            {!props.isLoading &&
            <div className="content">
                <table className={props.currentView === 'history' ? 'decisions-table' : ''}>
                    <HeaderRow toggleDescription={props.toggleDescription} showFullDescription={props.showFullDescription} currentView={props.currentView} sortRecords={props.sortRecords} startupFields={props.startupFields} historyFields={props.historyFields}/>
                    <tbody>
                    {props.currentView === 'startups' ? props.startups.map(renderStartup) : props.decisions.map(renderDecision)}
                    </tbody>
                </table>
            </div>
            }
            <div className="spinner">
                {props.isLoading &&
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                }
            </div>

        </div>
    );
}