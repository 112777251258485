import _ from 'lodash'
import moment from 'moment'
import {assignStartupScoring} from './business-logic'
import{ getSfConfig } from './config'
import jsforce from 'jsforce';

const KEREN_SF_ID = '00558000004LPaIAAW';

function formatSignal({Type__c:type,Direction__c: direction, Signal_Date__c: date}){
    return{
        type,
        direction,
        date
    }
}

function formatStartup({Id: startupId, Name, Website, NumberOfEmployees: Employees, Description, Employees__c: Headcount, Founded_On__c:Founded, linkedin_industry__c: Industry,
                           Company_LinkedIn__c: linkedinUrl, crunchbase_url__c: crunchbaseUrl, Total_Invested__c:Funding, signals, Last_Received_Funding_Date__c, Is_Israel_Related__c,ebsta1__ebstaLast_Activity_Date__c: lastActivityDate}){
    return {
        startupId,
        'Company Name': _.upperFirst(Name),
        Website,
        linkedinUrl,
        crunchbaseUrl,
        salesforceUrl: `https://aleph.lightning.force.com/lightning/r/Account/${startupId}/view`,
        salesforceSignalsUrl: `https://aleph.lightning.force.com/lightning/r/${startupId}/related/Signals__r/view`,
        Industry,
        Description,
        Headcount,
        Employees,
        Founded,
        Funding,
        lastActivityDate,
        'Last Received Funding Date': Last_Received_Funding_Date__c,
        'related to israel': Is_Israel_Related__c,
        signals: signals.map(formatSignal),
        Notes: ''
    }
}

function formatPassSignal(signal){
    return {
        Notes: signal.Details__c,
        'Company Name': _.upperFirst(_.get(signal, 'Startup_Account__r.Name')),
        'Website': _.get(signal, 'Startup_Account__r.Website'),
        startupId: signal.Startup_Account__r.Id,
        type: 'pass',
        date: signal.CreatedDate,
        salesforceUrl: `https://aleph.lightning.force.com/lightning/r/signal/${signal.Id}/view`,
    }
}

function getTaskType(task){
    const diff = Math.ceil(moment(task.ActivityDate).diff(moment(task.CreatedDate),'months') / 3)
    if(diff=== 1){
        return 'snooze1q'
    } else if(diff === 2){
        return 'snooze2q'
    } else if(diff === 0){
        return 'reachout'
    }
    return 'snooze1q'
}

function formatTask(task){
    return {
        Notes: task.Description,
        'Company Name': _.upperFirst(_.get(task, 'What.Name')),
        'Website': _.get(task, 'What.Website'),
        startupId: _.get(task, 'What.Id'),
        type: getTaskType(task),
        date: task.CreatedDate,
        salesforceUrl: `https://aleph.lightning.force.com/lightning/r/Task/${task.Id}/view`,

    }
}

function formatHistoryRecord(record){
    return _.has(record,'Signal_Date__c') ? formatPassSignal(record) : formatTask(record)
}

export class SalesforceConnector {

    connection = null

    _isReady = null

    constructor() {
        jsforce.browser.init(getSfConfig())

        this._isReady = new Promise((resolve) => {
            this._setIsReady = resolve
        })

        jsforce.browser.on('connect', _.once(this._setConnection))

        this.onceReady = _.once(async handler => {
            await this._isReady
            handler()
        })
    }

    _setConnection = (conn) => {
        this.connection = conn;
        this._setIsReady()
    }

    login() {
        jsforce.browser.login()
    }

    isLoggedIn = () => jsforce.browser.isLoggedIn()

    query = async (...args) => {
        await this._isReady
        return this.connection.query(...args)
    }

    getDealFinderStartups(){
        const query = 'SELECT id, Name, Website, NumberOfEmployees, Description, Last_Received_Funding_Date__c, ebsta1__ebstaLast_Activity_Date__c, Employees__c, Founded_On__c, linkedin_industry__c, Is_Israel_Related__c, Company_Linkedin__c, crunchbase_url__c, Total_Invested__c, ' +
            '(SELECT id, Type__c, Direction__c, Signal_Date__c  FROM signals__r where Type__c not in (\'Pass\'))' +
            '  FROM account' +
            ' WHERE Potential_Deal_Flow__c=true and Last_Deal_Date__c < Last_n_months:6'
        return this.query(query)
            .then(res => _(res.records).map(startup=> ({
                    ..._.omit(startup,['attributes','Signals__r']),
                    signals: _.get(startup, 'Signals__r.records', []).map(signal=> _.omit(signal,['attributes']))
                }))
                .map(formatStartup)
                .map(assignStartupScoring)
                .sortBy('score')
                .reverse()
                .value()
            )
    }

    async getDealFinderHistory(){
        const tasksQuery = `select id, Description, Subject, ActivityDate, CreatedDate, Status, what.Name, what.Id from task where 
        (owner.FirstName = 'aaron' or owner.FirstName = 'keren') and Subject like '%Reach out'`;
        const signalsQuery = `SELECT id, Startup_Account__r.Name, Startup_Account__r.Id, Startup_Account__r.Website, Type__c, Direction__c, Signal_Date__c, CreatedDate  FROM signal__c where Type__c = 'pass' and CreatedDate >= Last_n_months:6`;
        const {records: tasks} = await this.query(tasksQuery);
        const {records: signals} = await this.query(signalsQuery);
        return _(tasks)
            .concat(signals)
            .sortBy('CreatedDate')
            .reverse()
            .map(formatHistoryRecord)
            .value()
    }

    getHandlerByType(type){
        
        switch (type) {
            case 'reachout':
                return this.handleReachout;
            case 'snooze1q':
                return this.handleSnooze(1);
            case 'snooze2q':
                return this.handleSnooze(2);
            case 'pass':
                return this.handlePass;
            default:
                return ()=>{}
        }
    }


    handleReachout = (startup) => {
        return this.connection.sobject('Task')
            .insert({
                Subject: `Reach out to ${startup['Company Name']}`,
                Description: `${startup.Notes}\n\n Please make sure no one else in the fund is actively covering it before reaching out. If so, please tag Aaron in a comment and let him know.`,
                ActivityDate: new Date(),
                Status: 'Open',
                OwnerId: KEREN_SF_ID,
                WhatId: startup.startupId
            })
    
    }
    
    handleSnooze = (numberOfQs) => (startup) => {
        const monthsDelay = 3 * numberOfQs;
        return this.connection.sobject('Task')
            .insert({
                Subject: `Re-evaluate a reach out`,
                Description: startup.Notes,
                ActivityDate: moment().add(monthsDelay, 'months'),
                Status: 'Open',
                OwnerId: KEREN_SF_ID,
                WhatId: startup.startupId
            })
    }
    
    handlePass =(startup) => {
        return this.connection
            .sobject('Signal__c')
            .insert({
                Signal_Date__c: new Date(),
                Details__c: _.get(startup,'Notes',''),
                Direction__c: 'Negative',
                Startup_Account__c: startup.startupId,
                Type__c: 'Pass (passive, no interaction)',
            })
    }

}