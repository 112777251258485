export const STARTUP_FIELDS = [
    {
        name: 'Company',
        direction: ''
    },
    {
        name: 'Industry',
        direction: ''
    },
    {
        name: 'Description',
        direction: ''
    },
    {
        name: 'Headcount',
        direction: ''
    },
    {
        name: 'Employees',
        direction: ''
    },
    {
        name: 'Founded',
        direction: ''
    },
    {
        name: 'Funding',
        direction: ''
    },
    {
        name: 'Signals in Past 6 Months',
        direction: ''
    },
    {
        name: 'Notes',
        direction: ''
    },
    {
        name: 'Decision',
        direction: ''
    }
];

export const HISTORY_FIELDS = [
    {
        name: 'Company',
        direction: ''
    },
    {
        name: 'Decision',
        direction: ''
    },
    {
        name: 'Decision Date',
        direction: '▼'
    }
]