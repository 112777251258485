import moment from "moment/moment";
import _ from 'lodash'

export function assignStartupScoring(startup){
    const SCORING_CRITERIAS = {
        RECEIVED_FUNDING_3_YEARS_AGO: 3,
        RECEIVED_FUNDING_9_MONTHS_AGO: 100,
        RECEIVED_FUNDING_RECENTLY: 10,
        FRESH_POSITIVE_SIGNAL: 20,
        FRESH_NEUTRAL_SIGNAL: 10,
        STALE_POSITIVE_SIGNAL: 8,
        STALE_NEUTRAL_SIGNAL: 5,
        RELATED_TO_ISRAEL: 5,
        UNINTERESTING_INDUSTRY: -20,
        BIG_COMPANY: -20,
        MEDIUM_COMPANY: 50,
        GOT_TONS_OF_MONEY: -40,
        NO_EMAILS: 30
    };
    const scores =[];
    const reducer = (sum, n) => sum + n;
    if(!!startup['Last Received Funding Date']){
        if(moment(startup['Last Received Funding Date']) < moment().subtract('months', 36)){
            scores.push(SCORING_CRITERIAS.RECEIVED_FUNDING_3_YEARS_AGO)
        } else if(moment(startup['Last Received Funding Date']) > moment().subtract('months', 36) && moment(startup['Last Received Funding Date']) < moment().subtract('months', 9)){
            scores.push(SCORING_CRITERIAS.RECEIVED_FUNDING_9_MONTHS_AGO)
        } else if(moment(startup['Last Received Funding Date']) >= moment().subtract('months', 9)){
            scores.push(SCORING_CRITERIAS.RECEIVED_FUNDING_RECENTLY)
        }

    }

    scores.push(
        _(startup.signals)
            .filter(({direction, type}) => ['Positive', 'Neutral'].includes(direction) && type !== 'Introduction')
            .map(({direction, date}) => {
                const isFresh = moment(date) >=  moment().subtract('months', 24)
                if(isFresh){
                    return direction === 'Positive' ? SCORING_CRITERIAS.FRESH_POSITIVE_SIGNAL : SCORING_CRITERIAS.FRESH_NEUTRAL_SIGNAL
                } else{
                    return direction === 'Positive' ? SCORING_CRITERIAS.STALE_POSITIVE_SIGNAL : SCORING_CRITERIAS.STALE_NEUTRAL_SIGNAL
                }
            })
            .reduce(reducer, 0)
    );
    scores.push(startup['related to israel'] ? SCORING_CRITERIAS.RELATED_TO_ISRAEL : 0)
    scores.push(_.isEmpty(startup['lastActivityDate']) ? SCORING_CRITERIAS.NO_EMAILS : 0)

    const uninterestingIndustries = ['Biotechnology','Chemicals','Semiconductors','Computer Hardware','Pharmaceuticals','Medical Devices','Nanotechnology']
    scores.push(uninterestingIndustries.includes(startup['Industry']) ? SCORING_CRITERIAS.UNINTERESTING_INDUSTRY : 0)
    if(startup['Employees'] > 60){
        scores.push(SCORING_CRITERIAS.BIG_COMPANY)
    } else if(startup['Employees'] > 20 && startup['Employees'] < 60){
        scores.push(SCORING_CRITERIAS.MEDIUM_COMPANY)
    }
    scores.push(startup['Funding'] > 25000000 ? SCORING_CRITERIAS.GOT_TONS_OF_MONEY : 0)

    const finalScore = _.reduce(scores, reducer, 0)
    return {
        ...startup,
        score: finalScore
    }
}

